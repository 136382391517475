import { NMAAHCPropTypes, Theme, useWindowDimensions } from "assets";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./breadcrumb-navigation.module.scss";

// Height of this navigation, used for switch over points when multiple navigations are used
const NAV_HEIGHT = 72;

// Amount of padding above the section navigation blocks
const NAV_BLOCK_OFFSET = 170;

const EXHIBTIONS_PARENT = { title: "Exhibitions", uri: "exhibitions" };

// We need to add the Exhibitions page as a parent because it is not structurally the parent in craft
export const addExhibitionsParent = (parent) => {
  if (!parent) return EXHIBTIONS_PARENT;
  // We need to check if we've already added the parent, this is a way of avoiding passing a deep copy to the method
  else if (parent.parent === EXHIBTIONS_PARENT) return parent;
  else if (parent?.parent) addExhibitionsParent(parent.parent);
  else parent.parent = EXHIBTIONS_PARENT;
  return parent;
};

const BreadcrumbNavigation = ({ beginRef, typeHandle, parents, pageTitle }) => {
  const width = useWindowDimensions().width;
  const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

  // If begin ref is specified, that's when we want to begin showing the sticky nav - otherwise show even when scrolled to the top (0)
  let minScroll = 0;
  if (beginRef?.current) minScroll = beginRef.current.offsetTop - NAV_HEIGHT;
  // In some cases we are passing in a beginRef, but the reference hasn't been set (happens on scroll), so set something to a little past 0px
  else if (beginRef) minScroll = 10;

  const navClass = classNames(
    "container-fluid",
    styles.breadcrumbNavigation,
    Theme.addClass(Theme.Dark, true, true)
  );

  /** A function that takes an object of nested parents and their titles and uris and recursively displays them as breadcrumb links
   * Example input:
    {
      title: "Chapter Title", 
      uri: "chapter-uri",
      parent: {  
        title: "Pillar Title", 
        uri: "pillar-uri",
        parent: {
          title: "Exhibit Title", 
          uri: "exhibit-uri"
        },
      },
    }
    Returns: Exhibit Title / Pillar Title / Chapter Title /
   */
  const displayParentBreadcrumbs = (parent) => {
    return (
      <>
        {!isMobile && parent?.parent && displayParentBreadcrumbs(parent.parent)}
        <Link className={styles.parentTitle} to={`/${parent.uri}`}>
          <FormattedText
            outerElement={<span />}
            text={isMobile ? "Previous" : parent.title}
          />
        </Link>
        <div className={styles.divider} />
      </>
    );
  };

  return (
    <nav
      aria-label="Breadcrumbs"
      className={navClass}
      data-testid="breadcrumb-navigation"
      key="breadcrumb-navigation"
    >
      <div className={`row middle-xs ${styles.breadcrumbsContainer}`}>
        <div className={`center col-xs-12 col-lg-10 ${styles.breadcrumbs}`}>
          <Link
            aria-label="Parent Page"
            className={styles.backBtn}
            to={`/${parents.uri}`}
          >
            <i
              className={"icon-back"}
              data-testid="back-button"
              role="presentation"
            />
          </Link>
          {displayParentBreadcrumbs(parents)}
          <FormattedText className={styles.pageTitle} text={pageTitle} />
          <div className={styles.backToTop}>
            <ActionButton
              icon="back-top"
              onClick={() => {
                // Scroll to the top or before the cluster nav element
                const top =
                  typeHandle === "cluster" ? minScroll + NAV_BLOCK_OFFSET : 0;
                window.scrollTo({ top, behavior: "smooth" });
              }}
              text={isMobile ? "" : "Back to Top"}
              textPlacement={"left"}
              slim
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

BreadcrumbNavigation.propTypes = {
  beginRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  pageTitle: PropTypes.string,
  parents: PropTypes.shape({
    parent: PropTypes.shape({
      parent: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
  }).isRequired,
  typeHandle: NMAAHCPropTypes.TypeHandle,
};

const BreadcrumbNavigationFragment = graphql`
  fragment BreadcrumbNavigationFragment on CraftAPI_EntryInterface {
    typeHandle
    title
    parent {
      title
      uri
      parent {
        title
        uri
        parent {
          title
          uri
        }
      }
    }
  }
`;

/**
 * Converts the provided breadcrumb navigation data into a breadcrumb component
 *
 * @param breadcrumbNavData  the GraphQL response data
 * @returns               the breadcrumb navigation
 */
const convert = (breadcrumbNavData) => {
  return (
    <BreadcrumbNavigation {...breadcrumbNavData} key="breadcrumb-navigation" />
  );
};

export {
  BreadcrumbNavigationFragment,
  convert,
  BreadcrumbNavigation as default,
};
